import { NEWS_CATEGORIES } from '@/helpers/BoardHelper';

export const Category = (data: string) => {
  let categoryTag = ``;

  if (data === `280`) {
    categoryTag = `뉴스`;
  }
  if (data === `285`) {
    categoryTag = `성명`;
  }
  if (data === `290`) {
    categoryTag = `스토리`;
  }
  if (data === `295`) {
    categoryTag = `인터뷰`;
  }
  if (data === `300`) {
    categoryTag = `후원자참여`;
  }
  return categoryTag;
};

// 소식게시판 해시태그 : 띄어쓰기 없이 노출을 위한 부분
export const KeyTag = (data: string) => ({
  tag: data,
  tagName: NEWS_CATEGORIES[data] || ``,
});

export const KeysSet = (data: string) => {
  let keys: string[] = [];
  const keyArr: { tag: string; tagName: string }[] = [];
  const keywordList: { tag: string; tagName: string }[] = [];

  keys = data.split(`,`);

  if (keys.length > 4) {
    keys = keys.slice(0, 4);
  }

  keys.map((item) => keyArr.push(KeyTag(item)));

  // 태그네임이 중복될 경우 중복제거
  keyArr.forEach((x) => {
    if (!keywordList.some((y) => y.tagName === x.tagName)) {
      keywordList.push(x);
    }
  });

  return keywordList;
};

export const RemoveHtml = (data: string) => {
  let replaceData = ``;

  replaceData = data.replace(
    /<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/gi,
    ``,
  );
  replaceData = replaceData.replace(/<[^>]*>?/g, '');

  replaceData = replaceData.replace(/<a .*?>/g, '<text>');

  replaceData = replaceData.replace(/&nbsp;/gi, ` `);
  replaceData = replaceData.replace(/&ndash;/gi, `-`);
  replaceData = replaceData.replace(/&quot;/gi, `"`);
  replaceData = replaceData.replace(/&lt;/gi, `<`);
  replaceData = replaceData.replace(/&gt;/gi, `>`);
  replaceData = replaceData.replace(/&lsquo;/gi, `‘`);
  replaceData = replaceData.replace(/&rsquo;/gi, `’`);
  replaceData = replaceData.replace(/(<br>|<br\/>|<br \/>)/g, `\r\n`);
  return replaceData;
};
